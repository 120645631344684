<template>
  <div v-if="!isLoading">
    <DxDataGrid
        :data-source="dataSource"
        :show-borders="true"
        key-expr="userName"
        height="500px"
        :column-auto-width="true"
        :search-enabled="true"
    >

      <DxSearchPanel
          :visible="true"
          :width="240"
          placeholder="Search..."
      />
      
      <DxEditing
          :allow-updating="true"
          :allow-adding="false"
          :allow-deleting="false"
          :select-text-on-edit-start="true"
          start-edit-action="dblClick"
          :use-icons="true"
          mode="batch"
      />
      <DxPaging :enabled="false"/>

      <DxColumn data-field="userName" caption="Username" :allow-editing="false"/>
      <DxColumn data-field="name" caption="Name" :allow-editing="false"/>
      
      <DxColumn data-field="groupName" caption="Franchise Group">
        <DxLookup
            :data-source="franchiseList"
            display-expr="groupName"
            value-expr="groupName"
        />
      </DxColumn>

      <DxColumn data-field="isActive" caption="IsActive"/>
      
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxLookup, DxScrolling, DxSearchPanel
} from 'devextreme-vue/data-grid';
import DxSelectBox from 'devextreme-vue/select-box';
import DxCheckBox from 'devextreme-vue/check-box';
import {createStore} from "devextreme-aspnet-data-nojquery";
import {DxButton} from "devextreme-vue/button";

export default {
  name: "FranchiseUsers",
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxCheckBox,
    DxSelectBox,
    DxScrolling,
    DxButton,
    DxSearchPanel
  },

  data() {
    return {
      dataSource: null,
      isLoading: false,
      franchiseList: null
    };
  },

  mounted() {
    this.franchiseListLoad();
    this.loadData();
  },

  methods: {
    franchiseListLoad() {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.franchiseList = createStore({
        loadUrl: `${url}/api/slims-utilities/franchise-list`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            clientId: clientId
          }
        }
      });
    },
    
    loadData() {

      this.isLoading = true;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-utilities/users`,
        updateUrl: `${url}/api/slims-utilities/users/update?clientId=${clientId}`,
        key: 'userName',
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            ...ajaxOptions.data,
            clientId: clientId
          }
        }
      });

      this.isLoading = false;
    }
  }
}
</script>

<style scoped>
.data-grid {
  height: 250px
}
</style>