<template>
  <div v-if="!isLoading">
    <DxDataGrid
        class="data-grid2"
        :data-source="dataSource"
        :show-borders="true"
        key-expr="plu"
        height="500px"
        :column-auto-width="true"
        :allow-column-reordering="true"
    >

      <DxSearchPanel
          :visible="true"
          :width="240"
          placeholder="Search..."
      />
      <DxEditing
          :allow-updating="true"
          :allow-adding="false"
          :allow-deleting="false"
          :select-text-on-edit-start="true"
          start-edit-action="dblClick"
          :use-icons="true"
          mode="batch"
      />

      <DxScrolling
          column-rendering-mode="virtual"
          mode="infinite"/>

      <DxColumn data-field="plu" caption="PLU" :allow-editing="false"/>
      <DxColumn data-field="level1" caption="Level 1"/>
      <DxColumn data-field="level2" caption="Level 2"/>
      <DxColumn data-field="level3" caption="Level 3"/>
      <DxColumn data-field="level4" caption="Level 4"/>
      <DxColumn data-field="level5" caption="Level 5"/>
      <DxColumn data-field="guest_count" caption="Guest Count"/>
      <DxColumn data-field="lastsold" caption="Last Sold" :allow-editing="false" data-type="date"/>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxLookup, DxScrolling, DxSearchPanel
} from 'devextreme-vue/data-grid';
import DxSelectBox from 'devextreme-vue/select-box';
import DxCheckBox from 'devextreme-vue/check-box';
import {createStore} from "devextreme-aspnet-data-nojquery";
import {DxButton} from "devextreme-vue/button";

export default {
  name: "Products",
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxCheckBox,
    DxSelectBox,
    DxScrolling,
    DxButton,
    DxSearchPanel
  },
  
  props: {
    type: String
  },

  data() {
    return {
      dataSource: null,
      isLoading: false
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {

      this.isLoading = true;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-utilities/products/` + this.type,
        updateUrl: `${url}/api/slims-utilities/products/update?clientId=${clientId}`,
        key: 'plu',
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            ...ajaxOptions.data,
            clientId: clientId
          }
        }
      });
      
      this.isLoading = false;
    }
  }
}
</script>

<style scoped>
.data-grid2 {
  padding-top: 8px
}
</style>